import React from "react";

import { NavLink } from "react-router-dom";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { motion } from 'framer-motion/dist/framer-motion'

import Divider from "components/commons/Divider";

const statuses = {
  'Завършен': 'text-green-700 bg-green-50 ring-green-600/20',
  'Видян': 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  'Незапочнат': 'text-neutral-600 bg-neutral-50 ring-neutral-500/10',

  'Български език': 'text-sky-700 bg-sky-50 ring-sky-600/20',
  'Математика': 'text-purple-700 bg-purple-50 ring-purple-600/20',
  'Английски език': 'text-amber-700 bg-amber-50 ring-amber-600/20',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function label(test) {
  if (test.last_test_taking) {
    return <>
      Завършен на <time>{test.last_test_taking.created_at}</time>
    </>
  } else {
    return <>
      Добавен на <time>{test.created_on}</time>
    </>
  }
}

function topicClasses(topic, i) {
  return 'anchorable target:underline pb-5' + (i === 0 && ' pt-16 md:pt-0')
}

function topicTitle(topic, i) {
  return (
    <div id={topic.id} className={topicClasses(topic, i)}>
      <h3 className="text-xl font-bold leading-6 text-sky-700">{topic.index}. {topic.title}</h3>
      {/*<p className="mt-2 max-w-4xl text-sm text-neutral-500">
        Workcation is a property rental website. Etiam ullamcorper massa viverra consequat.
      </p>*/}
    </div>
  )
}

function finishedTestMenuItems(test) {
  return (
    <>
      <Menu.Item>
        {({ active }) => (
          <NavLink
            to={`/tests/${test.id}`}
            className={classNames(
              active ? 'bg-neutral-50' : '',
              'block px-3 py-1 text-sm leading-6 text-neutral-900'
            )}
          >
            Виж резултат
          </NavLink>
        )}
      </Menu.Item>
    </>
  )
}

function unfinishedTestMenuItems(test) {
  return (
    <>
      <Menu.Item>
        {({ active }) => (
          <NavLink
            to={`/tests/${test.id}`}
            className={classNames(
              active ? 'bg-neutral-50' : '',
              'block px-3 py-1 text-sm leading-6 text-neutral-900'
            )}
          >
            Обучителен режим
          </NavLink>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <NavLink
            to={`/tests/${test.id}?mode=exam`}
            className={classNames(
              active ? 'bg-neutral-50' : '',
              'block px-3 py-1 text-sm leading-6 text-neutral-900'
            )}
          >
            Режим "Изпит"
          </NavLink>
        )}
      </Menu.Item>
    </>
  )
}

function finished(test) {
  return test.status === 'Завършен';
}

function threeDotsMenu(test) {
  return (
    <Menu as="div" className="relative flex-none">
      <Menu.Button className="-m-2.5 block p-2.5 text-neutral-500 hover:text-neutral-900">
        <span className="sr-only">Open options</span>
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={`absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-neutral-900/5 focus:outline-none ${finished(test) ? 'w-32' : 'w-40'}`}>
          {finished(test) && finishedTestMenuItems(test)}
          {!finished(test) && unfinishedTestMenuItems(test)}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

function renderTest(test, badgesFor) {
  let badge = badgesFor === 'status' ? test.status : test.subject.title

  return <motion.li key={test.id} layout="position" className="flex items-center justify-between gap-x-6 py-5">
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <NavLink to={`/tests/${test.id}`}>
            <p className="text-base font-semibold leading-6 text-neutral-900">{test.title}</p>
          </NavLink>
          <p
            className={classNames(
              statuses[badge],
              'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
            )}
          >
            {badge}
          </p>
        </div>
        <div className="mt-1 flex items-center gap-x-2 leading-5 text-neutral-400">
          <p className="whitespace-nowrap italic">
            {label(test)}
          </p>
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
            <circle cx={1} cy={1} r={1} />
          </svg>
          <p className="truncate">{test.description}</p>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <NavLink
          to={`/tests/${test.id}`}
          className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 hover:bg-neutral-50 sm:block"
        >
          Към теста
        </NavLink>
        {threeDotsMenu(test)}
      </div>
  </motion.li>
}

export default function BadgedList({
  tests = [],
  topics = [],
  badgesFor = 'status' // could be "status" or "subject"
}) {
  return (
    <>
      {topics.length > 0 && (
        topics.map((topic, i) => (
          <motion.div key={topic.id}>
            {topicTitle(topic, i)}

            <ul role="list" className="divide-y divide-neutral-100">
              {topic.tests.map((test) => (renderTest(test, badgesFor)))}
            </ul>
            <div className="mt-10"></div>
            {i != topics.length - 1 && <Divider />}
            <div className="mt-10"></div>
          </motion.div>
        ))
      )}

      {tests.length > 0 && (
        tests.map((test) => (renderTest(test, badgesFor)))
      )}
    </>
  )
}
