import React from "react";
import Link from 'next/link'

import LogoSpin from "../../commons/LogoSpin";

import { Container } from './Container'
import { NavLinkMy } from './NavLinkMy'
import { NavLinkNextJS } from './NavLinkNextJS'

function scrollToTop() {
  window.scrollTo({top: 0, behavior: 'smooth'})
}

export function Footer() {
  return (
    <footer className="bg-neutral-50">
      <Container>
        <div className="py-16">
          <div className="w-fit m-auto cursor-pointer" onClick={() => scrollToTop()}>
            <LogoSpin height="small" />
          </div>
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 flex justify-center gap-x-6">
              <NavLinkNextJS href="#features">Elenor</NavLinkNextJS>
              <NavLinkMy href="/demo">Демо</NavLinkMy>
              <NavLinkNextJS href="#pricing">Цени</NavLinkNextJS>
              <NavLinkNextJS href="#contact">Контакти</NavLinkNextJS>
            </div>
          </nav>
        </div>
        <div className="flex flex-col items-center border-t border-neutral-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <div className="flex gap-x-6">
            {/*<Link
              href="https://twitter.com"
              className="group"
              aria-label="TaxPal on Twitter">
              <svg
                aria-hidden="true"
                className="h-6 w-6 fill-neutral-500 group-hover:fill-neutral-700"
              >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0 0 22 5.92a8.19 8.19 0 0 1-2.357.646 4.118 4.118 0 0 0 1.804-2.27 8.224 8.224 0 0 1-2.605.996 4.107 4.107 0 0 0-6.993 3.743 11.65 11.65 0 0 1-8.457-4.287 4.106 4.106 0 0 0 1.27 5.477A4.073 4.073 0 0 1 2.8 9.713v.052a4.105 4.105 0 0 0 3.292 4.022 4.093 4.093 0 0 1-1.853.07 4.108 4.108 0 0 0 3.834 2.85A8.233 8.233 0 0 1 2 18.407a11.615 11.615 0 0 0 6.29 1.84" />
              </svg>
            </Link>*/}

            <svg onClick={() => scrollToTop()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
              <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
            </svg>
          </div>
          <p className="mt-6 text-sm text-neutral-500 sm:mt-0">
            &copy; {new Date().getFullYear()} Elenor, България
          </p>
        </div>
      </Container>
    </footer>
  )
}
