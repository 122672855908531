import React, { useState, useEffect } from "react";

import usersApi from "apis/users";
import Plans from "./Plans";
import SuccessDisplay from "./SuccessDisplay";
import { PageLoader } from "neetoui";
import { useUserState } from "contexts/user";

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

const Subscription = () => {
  const { user } = useUserState();

  const [loading, setLoading] = useState(true);

  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(user?.premium);
  let [sessionId, setSessionId] = useState('');

  const [fetchedUser, setFetchedUser] = useState({});

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      setLoading(true);
      const { data } = await usersApi.fetchUser(user.id);

      setFetchedUser(data);
      setSuccess(data.premium)
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Операцията беше отменента. Върнете се отново на тази страница за да стартирате отново."
      );
    }
  }, [sessionId]);

  if (loading) {
    return <div className="animate-fade py-10">
             <PageLoader text="Един момент..."/>
           </div>;
  }

  if (!success && message === '') {
    return <Plans/>
  } else if (success) {
    return <SuccessDisplay sessionId={sessionId} user={fetchedUser} />
  } else {
    // return <Message message={message} />
    return <Plans/>
  }
};

export default Subscription;
