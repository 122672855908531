import React, { useState, useEffect } from "react";

import { useParams } from 'react-router-dom';
import { PageLoader } from "neetoui";

import subjectsApi from "apis/subjects";
import BackButton from "components/commons/BackButton";
import BadgedList from "components/commons/BadgedList";

import CategoryFilter from "./CategoryFilter";
import GradeFilter from "./GradeFilter";
import Toggle from "components/commons/Toggle";
import Navigation from "./Navigation";

const SubjectTests = () => {
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [grade, setGrade] = useState(null);
  const [hideCompleted, setHideCompleted] = useState(false);
  const [onlyFree, setOnlyFree] = useState(false);
  const [allGrades, setAllGrades] = useState([]);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    if (allGrades.length === 0) {
      fetchGrades();
    }

    let gradeFromURL = new URLSearchParams(window.location.search).get('grade')
    if (!grade && gradeFromURL) {
      fetchGrade(gradeFromURL)
      // setGrade(allGrades.find((gr) => gr.id === gradeFromURL))
    }

    if (grade) {
      fetchSubjectTests();
    }

    const url = new URL(window.location.href);
    if (grade) {
      url.searchParams.set('grade', grade);
    }
    window.history.replaceState(null, null, url); // or pushState
  }, [category, grade, hideCompleted, onlyFree])

  let { id } = useParams();

  const resetFilters = () => {
    setCategory([])
    setHideCompleted(false)
    setOnlyFree(false)
  }

  const isClearFiltersDisabled = () => {
    return category.length === 0 && hideCompleted === false && onlyFree === false
  }

  const fetchGrade = async (gradeFromURL) => {
    try {
      const {
        data: { single_grade },
      } = await subjectsApi.fetchGrade(id, gradeFromURL);
      setGrade(single_grade.id);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchGrades = async () => {
    try {
      setLoading(true);
      const {
        data: { grades },
      } = await subjectsApi.fetchGrades(id);
      setAllGrades(grades);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubjectTests = async () => {
    try {
      const {
        data: { topics },
      } = await subjectsApi.fetchTests(id, {category: category, grade: grade, hideCompleted: hideCompleted, onlyFree: onlyFree});
      setTopics(topics);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="animate-fade py-10"><PageLoader text="Един момент..."/></div>;
  }

  return (
    <>
      <BackButton/>
      <div className="flex items-center justify-center">
        <div className="bg-neutral-50 shadow sm:rounded-md mt-4 mb-10 md:mb-20 md:max-w-3xl w-full">
          <div className="px-4 py-5 sm:p-6">
            <GradeFilter grade={grade} setGrade={setGrade} allGrades={allGrades}/><div className="mt-8"></div>

            {grade && (
              <>
                <CategoryFilter category={category} setCategory={setCategory} /><div className="mt-8"></div>
                <Toggle enabled={hideCompleted} setEnabled={setHideCompleted} title="Скрий завършени тестове" subtitle="Скрийте тестове, които вече сте направили." toggleClasses="md:mr-24"/><div className="mt-5"></div>
                <Toggle enabled={onlyFree} setEnabled={setOnlyFree} title="Само безплатни тестове" subtitle="Покажете само тестове, които не изискват активен платен абонамент." toggleClasses="md:mr-24" />

                <div className="flex items-center justify-center">
                  {
                    isClearFiltersDisabled() ? (
                      <button type="button" className="rounded-md bg-neutral-100 px-3 py-2 text-sm font-semibold text-neutral-400 shadow-sm hover:bg-neutral-100 mt-7" disabled>
                        Изчисти филтрите
                      </button>
                    ) : (
                      <button type="button" className="rounded-md bg-primary-50 px-3 py-2 text-sm font-semibold text-primary-600 shadow-sm hover:bg-primary-100 mt-7" onClick={resetFilters}>
                        Изчисти филтрите
                      </button>
                    )
                  }
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {grade && (
        <div className="grid grid-cols-1 md:space-y-0 md:grid-cols-[33%_65%] xl:grid-cols-[22%_76%] md:gap-[2%]">
          <Navigation topics={topics}/>
          <div>
            <BadgedList topics={topics} badgesFor='status' />
          </div>
        </div>
      )}
    </>
  );
};

export default SubjectTests;
