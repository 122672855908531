import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import MyScrollToTop from "components/commons/MyScrollToTop";
import Classic from "components/Dashboard/Test/Classic";
import ImageClick from "components/Dashboard/Test/ImageClick";
import Mathable from "components/commons/Mathable";
import LogoSpin from "components/commons/LogoSpin";
import { PageLoader } from "neetoui";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import testsApi from "apis/tests";

import { GridPattern } from './GridPattern'

export default function Demo() {
  function HeroGrid() {
    return (
      <>
        <div className="relative h-[636px] w-11/12 m-auto shadow-md rounded-3xl mb-10 bg-gradient-to-b from-white to-neutral-100/75">
          <div className="absolute inset-x-0 top-0 h-[636px] overflow-hidden rounded-t-4xl">
            <GridPattern
              className="absolute inset-x-0 -top-14 h-[1000px] w-full fill-primary-50 stroke-neutral-950/5 [mask-image:linear-gradient(to_bottom_left,white_40%,transparent_50%)]"
              yOffset={-270}
              interactive
            />
            <NavLink to="/" className="flex gap-x-3 absolute left-3 top-7 sm:left-8 sm:top-9 hover:underline">
              <button className="inline-flex items-center text-sm/6 font-semibold text-gray-900">
                <ChevronLeftIcon className="ml-0.5 h-5 w-5 text-neutral-700" aria-hidden="true" /> Начало
              </button>
            </NavLink>
            <NavLink to="/signup" className="flex gap-x-3 absolute right-3 top-5 sm:right-8 sm:top-8 hover:underline">
              <LogoSpin height="micro" />
              <button className="inline-flex items-center text-sm/6 font-semibold text-gray-900">
                Създай акаунт <ChevronRightIcon className="ml-0.5 h-5 w-5 text-neutral-700" aria-hidden="true" />
              </button>
            </NavLink>
            <div className="z-10 relative mx-auto max-w-7xl px-6 lg:px-8 mt-32 md:mt-56">
              <div className="max-w-3xl">
                <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
                  Изпробвайте Еленор
                </h1>
                <p className="mt-6 text-xl text-neutral-600">
                  Направете следния демо тест и придобийте представа как изглежда платформата Еленор. Обърнете внимание на <i>трите начина за помощ</i> - жокери, спомагателни въпроси и обяснения след решението на теста.
                </p>
              </div>
              <div className="mt-10 flex flex-col sm:flex-row items-center gap-x-6 gap-y-6">
                <button onClick={() => onLoadTestClicked('mandatory')} className="rounded transition-colors duration-300 ease-in-out bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600" >
                  Виж демо тест
                </button>
                <button onClick={() => onLoadTestClicked('casual')} className="inline-flex items-center text-sm/6 font-semibold text-gray-900">
                  Или направи развлекателен тест <ChevronRightIcon className="ml-0.5 h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  function scrollToTest() {
    document.getElementById('demoTest').scrollIntoView({behavior: "smooth"});
  }

  function onLoadTestClicked(category) {
    if (test.category !== category) {
      fetchTest(category);
    }

    scrollToTest();
  }

  const [loading, setLoading] = useState(true);
  const [test, setTest] = useState({});

  useEffect(() => {
    fetchTest('mandatory');
  }, []);

  const fetchTest = async (category) => {
    try {
      setLoading(true);
      const {
        data: { test },
      } = await testsApi.fetchDemoTest(category);

      setTest(test);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="animate-fade py-10">
             <PageLoader text="Един момент..."/>
           </div>;
  }

  return (
    <>
      <HeroGrid />
      <div id="demoTest" className="relative py-10 px-4 sm:px-6 lg:px-8">
        {test.kind === 'classic' && <Mathable content={<Classic test={test} renderingForDemoPage={true} />} />}
        {test.kind === 'image_click' && (
          <div className="sm:px-10 md:px-24">
            <ImageClick test={test} renderingForDemoPage={true} />
          </div>
        )}
      </div>
      <MyScrollToTop />
    </>
  )
}
