import axios from "axios";

const fetch = () => axios.get("api/v1/subjects");
const fetchGrade = (id, gradeId) => axios.get(`api/v1/subjects/${id}/grade`, {params: {grade: gradeId}});
const fetchGrades = (id) => axios.get(`api/v1/subjects/${id}/grades`);
const fetchTests = (id, params) => axios.get(`api/v1/subjects/${id}/tests`, {params: params});
const sendEnglishFeedback = (fields) => axios.post(`api/v1/english_feedbacks`, {feedback: fields});

const subjectsApi = {
  fetch,
  fetchGrade,
  fetchGrades,
  fetchTests,
  sendEnglishFeedback
};

export default subjectsApi;
