import React, { useState, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';
import testsApi from "apis/tests";

// const stats = [
//   { name: 'Брой въпроси', value: '2123', unit: 'в <b>125 теста</b>' },
//   { name: 'По НВО/ДЗИ формат', value: '53', unit: 'теста' },
//   { name: 'С помощни пояснения', value: '87.5', unit: '%' },
// ]

export default function Stats() {
  useEffect(() => {
    fetchStats();
  }, []);
  const [stats, setStats] = useState([]);

  const fetchStats = async () => {
    const {
      data: { totals },
    } = await testsApi.fetchStats();

    setStats(totals);
  };

  return (
    <div className="mx-auto max-w-7xl">
      <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-1 lg:grid-cols-1">
        {stats.map((stat) => (
          <div key={stat.name} className="px-4 py-6 sm:px-6 lg:px-8">
            <p className="text-sm font-medium leading-6 text-neutral-400">{stat.name}</p>
            <p className="mt-2 flex items-baseline gap-x-2">
              <span className="text-4xl font-semibold tracking-tight">{stat.value}</span>
              {stat.unit ? <span className="text-sm text-neutral-400">{ReactHtmlParser(stat.unit)}</span> : null}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}
