import React from "react";
import questionsApi from "apis/questions";
import { subscribe, publish } from "common/events";
import { CheckCircleIcon } from '@heroicons/react/20/solid'

const MarkOpenQuestionAsCorrect = ({
  question,
  subquestion
}) => {
  function onSuccess(response) {
    publish('last-test-taking-updated', response.data.test_taking);
  }

  const submit = async () => {
    try {
      if (subquestion) {
        questionsApi.correctOpenSubquestion(question.id, subquestion.id).then(onSuccess)
      } else {
        questionsApi.correctOpenQuestion(question.id).then(onSuccess)
      }
    } catch (error) {
      logger.error(error)
    } finally {
      // no op
    }
  };

  return (
    <>
      <button type="button" className="peer inline-flex items-center gap-x-1 rounded-md bg-transparent hover:bg-green-50 px-2 py-1.5 text-xs font-light text-green-500 hover:text-green-800 transition-colors duration-300 ease-in-out my-1.5" onClick={() => submit()}>
        <CheckCircleIcon aria-hidden="true" className="-ml-0.5 h-4 w-4" />
        Маркирай като верен
      </button>
      <p className="hidden peer-hover:block animate-fadeMedium text-xs font-light pl-1">Ако отговорът ви е правилен, но не съвпада точно със зададения от нас верен отговор, може да коригирате оценяването с този бутон.</p>
    </>
  );
};

export default MarkOpenQuestionAsCorrect;
