import React from "react";
import EnglishFeedback from "./EnglishFeedback";
import { NavLink } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid'

const SubjectsList = ({
  subjects,
  fetchSubjects
}) => {
  function render(subject, renderComingSoon = false) {
    return (
      <>
        <img className="shine-on-hover rounded-t-lg" src={subject.avatar} alt="" />
        <div className="p-4">
          {!renderComingSoon && (
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-neutral-900 dark:text-white">{subject.title}</h5>
          )}
          {renderComingSoon && (
            <div className="inline-flex items-center gap-x-1">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-neutral-900 dark:text-white">{subject.title}</h5>
              <span className="-mt-5 rotate-3 inline-flex items-center rounded-md bg-neutral-50 px-2 py-1 text-xs font-medium text-neutral-600 ring-1 ring-inset ring-neutral-700/10">
                Очаквайте
              </span>
            </div>
          )}
          <p className="mb-3 h-20 font-normal text-neutral-700 dark:text-neutral-400">{subject.description}</p>
          <button className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-primary-600 rounded-lg hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-600 dark:focus:ring-primary-700">
            Избери
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="mx-auto flex items-center justify-center">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {subjects.map(subject => (
            <div className="cursor-pointer max-w-sm bg-white border border-neutral-200 rounded-lg shadow-md hover:bg-neutral-50 dark:bg-neutral-800 dark:border-neutral-700 dark:hover:bg-neutral-700" key={subject.id}>
              {subject.title === 'Английски език' && (
                <EnglishFeedback content={
                  <div className="grayscale myGrayscale opacity-80">
                    {render(subject, true)}
                  </div>
                } />
              )}

              {subject.title !== 'Английски език' && (
                <NavLink to={`/subjects/${subject.id}/tests`} >
                  {render(subject)}
                </NavLink>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SubjectsList;
