import axios from "axios";

const fetch = () => axios.get("api/v1/questions");
const submitAnswers = payload => axios.post("api/v1/questions/submit_answers", payload);
const fetchHistory = () => axios.get("api/v1/questions/history");
const correctOpenQuestion = (questionId) => axios.post(`api/v1/questions/${questionId}/mark_open_question_as_correct`);
const correctOpenSubquestion = (questionId, subquestionId) => axios.post(`api/v1/questions/${questionId}/mark_open_subquestion_as_correct`, {
  correction: {subquestion_id: subquestionId}
});
// const fetchSubjects = () => axios.get("api/v1/subjects");

const questionsApi = {
  fetch,
  submitAnswers,
  fetchHistory,
  correctOpenQuestion,
  correctOpenSubquestion
  // fetchSubjects
};

export default questionsApi;
