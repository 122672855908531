import React, { useState } from "react";
import SlideOver from "components/commons/SlideOver";
import StarRating from "components/commons/StarRating";
import CommentTextarea from "components/commons/CommentTextarea";
import { ChatBubbleLeftRightIcon } from '@heroicons/react/20/solid'
import { useUserState } from "contexts/user";
import testsApi from "apis/tests";

export default function TestFeedback({
  test,
  renderingForDemoPage = false
}) {
  const [slideOverOpen, setSlideOverOpen] = useState(false)
  const [rating, setRating] = useState(null)
  const [testErrorComment, setTestErrorComment] = useState('')
  const [generalFeedbackComment, setGeneralFeedbackComment] = useState('')
  const [generalFeedbackEmoji, setGeneralFeedbackEmoji] = useState(null)

  const { user } = useUserState();

  function canSubmit() {
    return rating != null || testErrorComment.length > 0 || generalFeedbackComment.length > 0;
  }

  function submitRequest() {
    if (renderingForDemoPage) {
      return testsApi.sendDemoTestFeedback;
    } else {
      return testsApi.sendTestFeedback;
    }
  }

  function submitFeedback() {
    if (canSubmit()) {
      const fields = {
        star_rating: rating,
        error_comment: testErrorComment,
        general_comment: generalFeedbackComment,
        general_emoji: generalFeedbackEmoji
      }

      submitRequest()(test.id, fields).then((response) => {
        setRating(null);
        setTestErrorComment('');
        setGeneralFeedbackComment('');
        setGeneralFeedbackEmoji(null);
        setSlideOverOpen(false);
      })
    }
  }

  return (
    <>
      <SlideOver
        open={slideOverOpen}
        setOpen={setSlideOverOpen}
        title="Обратна връзка за теста"
        widthClass="max-w-xl"
        content={
          <>
            <div className="flex flex-col space-y-8">
              <div>
                <p className="text-sm font-medium text-neutral-900">
                  Моля, оценете този тест от една до пет звезди.
                </p>
                <p className="text-sm text-neutral-500 mt-0.5">
                  Тестовете с най-ниски оценки ще бъдат преразгледани и подобрени.
                </p>
                <StarRating rating={rating} setRating={setRating}/>
              </div>
              <div>
                <p className="text-sm font-medium text-neutral-900">
                  Искате ли да докладвате за грешка в теста?
                </p>
                <p className="text-sm text-neutral-500 mt-0.5">
                  Ако в теста има допусната грешка, моля да ни извините.
                </p>
                <div className="mt-5">
                  <CommentTextarea comment={testErrorComment} setComment={setTestErrorComment} />
                </div>
              </div>
              <div>
                <p className="text-sm font-medium text-neutral-900">
                  Споделете впечатлението си от теста и платформата като цяло.
                </p>
                <p className="text-sm text-neutral-500 mt-0.5">
                  Ако Ви липсва конкретна функционалност или имате прпеоръки как да направим платформата по-полезна, ще се радваме да научим.
                </p>
                <div className="mt-5">
                  <CommentTextarea comment={generalFeedbackComment} setComment={setGeneralFeedbackComment} showEmoji={true} emoji={generalFeedbackEmoji} setEmoji={setGeneralFeedbackEmoji} />
                </div>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className={`${canSubmit() ? 'bg-primary-600 hover:bg-primary-500 cursor-pointer' : 'bg-neutral-400 cursor-default'} inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600`}
                  onClick={() => submitFeedback()}
                >
                  <ChatBubbleLeftRightIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                  Изпрати
                </button>
              </div>
            </div>
          </>
        }
      />

      <button type="button" className="inline-flex items-center gap-x-1.5 rounded bg-transparent px-3 py-2 text-sm font-semibold text-neutral-500 hover:text-neutral-600 transition-colors duration-300 ease-in-out hover:shadow-subtle hover:bg-neutral-50 my-1.5" onClick={() => setSlideOverOpen(true)}>
        <ChatBubbleLeftRightIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
        Обратна връзка
      </button>
    </>
  )
}
