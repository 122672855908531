import React, { useState, useEffect } from "react";
import SlideOver from "components/commons/SlideOver";
import StarRating from "components/commons/StarRating";
import CommentTextarea from "components/commons/CommentTextarea";
import { ChatBubbleLeftRightIcon } from '@heroicons/react/20/solid'
import { useUserState } from "contexts/user";
import subjectsApi from "apis/subjects";

export default function EnglishFeedback({
  content
}) {
  const [slideOverOpen, setSlideOverOpen] = useState(false)
  const [comparisonToOtherSubjects, setComparisonToOtherSubjects] = useState('')
  const [desiredExercises, setDesiredExercises] = useState('')
  const [neededFor, setNeededFor] = useState('')

  const { user } = useUserState();

  function canSubmit() {
    return comparisonToOtherSubjects.length > 0 || desiredExercises.length > 0 || neededFor.length > 0;
  }

  function submitFeedback() {
    if (canSubmit()) {
      const fields = {
        comparison_to_other_subjects: comparisonToOtherSubjects,
        desired_exercises: desiredExercises,
        needed_for: neededFor
      }

      subjectsApi.sendEnglishFeedback(fields).then((response) => {
        setComparisonToOtherSubjects('');
        setDesiredExercises('');
        setNeededFor('');
        setSlideOverOpen(false);
      })
    }
  }

  return (
    <>
      <SlideOver
        open={slideOverOpen}
        setOpen={setSlideOverOpen}
        title="Включването на Английски език в платформата"
        widthClass="max-w-xl"
        content={
          <>
            <div className="flex flex-col space-y-8">
              <div>
                <p className="text-sm font-medium text-neutral-900">
                  Все още не сме готови с помощните тестове по английски език
                </p>
                <p className="text-sm text-neutral-500 mt-0.5">
                  Събираме обратна връзка за нуждата на потребителите от материал по английски език, на база на която ще определим приоритета му спрямо добавяне на още материал по другите предмети.
                </p>
                <p className="text-sm text-neutral-500 mt-3">
                  <i>При интерес, моля оставете вашето мнение на следните въпроси.</i>
                </p>
              </div>
              <div>
                <p className="text-sm font-medium text-neutral-900">
                  Английски език спрямо математика и български език
                </p>
                <p className="text-sm text-neutral-500 mt-0.5">
                  Имате ли нужда от помощни тестове по английски език? Ако да, по-високо или по-ниско приоритетен за вас е английският език спрямо математика и български език?
                </p>
                <div className="mt-5">
                  <CommentTextarea comment={comparisonToOtherSubjects} setComment={setComparisonToOtherSubjects} />
                </div>
              </div>

              <div>
                <p className="text-sm font-medium text-neutral-900">
                  От какви упражнения по английски език имате нужда?
                </p>
                <p className="text-sm text-neutral-500 mt-0.5">
                  Уражненията по английски езикк могат да се разделят на граматически/речникови, четене с разбиране, слушане, писане, говорене. Има ли по-високо и по-ниско приоритетни за вас от тези видове?
                </p>
                <div className="mt-5">
                  <CommentTextarea comment={desiredExercises} setComment={setDesiredExercises} />
                </div>
              </div>

              <div>
                <p className="text-sm font-medium text-neutral-900">
                  Защо се нуждаете от помощ по английски език?
                </p>
                <p className="text-sm text-neutral-500 mt-0.5">
                  Имате ли конкретна цел, например помощ с материала в училище, курс или сертификат?
                </p>
                <div className="mt-5">
                  <CommentTextarea comment={neededFor} setComment={setNeededFor} />
                </div>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className={`${canSubmit() ? 'bg-primary-600 hover:bg-primary-500 cursor-pointer' : 'bg-neutral-400 cursor-default'} inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600`}
                  onClick={() => submitFeedback()}
                >
                  <ChatBubbleLeftRightIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                  Изпрати
                </button>
              </div>
            </div>
          </>
        }
      />

      <div onClick={() => setSlideOverOpen(true)}>
        {content}
      </div>
    </>
  )
}
