import React from "react";
import { NavLink } from "react-router-dom";
import { ChevronRightIcon } from '@heroicons/react/20/solid'

export function CallToActionSecond() {
  return (
    <div className="mx-auto w-full">
      <div className="relative isolate overflow-hidden bg-neutral-900 px-6 py-24 text-center shadow-2xl sm:px-16">
        <h2 className="text-balance text-4xl font-semibold tracking-tight text-white sm:text-5xl">
          Виж тестовете в платформата
        </h2>
        <p className="mx-auto mt-6 max-w-xl text-pretty text-lg/8 text-neutral-300">
          Създайте акаунт и разгледайте тестовете в платформата Еленор. Може да направите и кратък демо тест без регистрация.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <NavLink to="/signup" className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-neutral-900 shadow-sm hover:bg-neutral-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white" >
            Регистрирай се
          </NavLink>
          <NavLink to="/demo" className="text-sm/6 font-semibold text-white inline-flex items-center">
            Или виж демо <ChevronRightIcon className="ml-0.5 h-5 w-5 text-white" aria-hidden="true" />
          </NavLink>
        </div>
        <svg
          viewBox="0 0 1024 1024"
          aria-hidden="true"
          className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
        >
          <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
          <defs>
            <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
              <stop stopColor="#7775D6" />
              <stop offset={1} stopColor="#E935C1" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  )
}
