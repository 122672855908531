import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { MY_TESTS_PATH } from "components/routeConstants";

import MyTests from "../MyTests";
import Subjects from "../Subjects";
import Stats from "./Stats";
import LogoSpin from "components/commons/LogoSpin";

const Home = () => {
  const [tests, setTests] = useState({});

  return (
    <>
      {/*<LogoSpin classes="pb-10" />*/}

      <div className='flex flex-col items-center pb-10'>
        <img
          className="h-24 mt-4 -mx-2 w-auto"
          src="/bg-indigo3.svg"
          alt="Eleanor"
        />
      </div>

      <div className="space-y-10">
        <div className="mx-auto divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow lg:max-w-5xl">
          <div className="bg-neutral-100/75 px-4 py-5 sm:px-6 lg:px-10">
            <h3> Разгледай предметите </h3>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <Subjects/>
          </div>
        </div>

        <div className="mx-auto space-y-10 sm:space-y-10 grid grid-cols-1 md:space-y-0 md:grid-cols-[66%_32%] md:gap-[2%] lg:max-w-5xl">
          <div>
            <div className="mx-auto divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
              <div className="bg-neutral-100/75 px-4 py-5 sm:px-6 lg:px-10">
                <h3> Моите тестове </h3>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <div className="max-h-160 overflow-hidden">
                  <MyTests forDashboard={true} setDashboardTests={setTests}/>
                </div>

                {tests.length > 0 && (
                  <div className="py-5">
                    <NavLink
                      to={MY_TESTS_PATH}
                      className="mx-auto block rounded-lg"
                    >
                        <button className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-primary-600 rounded-lg hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                            Към моите тестове
                            <svg className="w-3 h-3 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                            </svg>
                        </button>
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
              <div className="bg-neutral-100/75 px-4 py-5 sm:px-6 lg:px-10">
                <h3> Еленор в числа </h3>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <Stats/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Home;
